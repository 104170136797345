import React from "react";
import * as styles from "../styles/form-contactanos.module.css"
import * as stylesContactanos from "../styles/contactanos.module.css"
// import * as scriptContactanos from "../assets/vendor/preline/dist/form-contactanos.js"
// import Layout from "./layout"
import { Helmet } from "react-helmet"
// import Box from "@material-ui/core/Box"

export default function FormContactanos() {

    function validateEmail1552583000122814074() {
        var form = document.forms['WebToLeads1552583000122814074'];
        var emailFld = form.querySelectorAll('[ftype=email]');
        // var i = 0;
        for (let i = 0; i < emailFld.length; i++) {
            var emailVal = emailFld[i].value;
            if ((emailVal.replace(/^\s+|\s+$/g, '')).length != 0) {
                var atpos = emailVal.indexOf('@');
                var dotpos = emailVal.lastIndexOf('.');
                if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= emailVal.length) {
                    alert('Por favor, introduce una dirección de correo electrónico válida.');
                    emailFld[i].focus();
                    return false;
                }
            }
        }
        return true;
    }
    function checkMandatory1552583000122814074(event) {
        var mndFileds = new Array('First Name', 'Last Name', 'LEADCF26', 'LEADCF38');
        var fldLangVal = new Array('Nombre', 'Apellido', 'Celular', '\xBFC\xF3mo\x20se\x20entero\x20de\x20nosotros\x3F');
        for (let i = 0; i < mndFileds.length; i++) {
        //   debugger  
            var fieldObj = document.forms['WebToLeads1552583000122814074'][mndFileds[i]];
            if (fieldObj) {
                if (((fieldObj.value).replace(/^\s+|\s+$/g, '')).length == 0) {
                    if (fieldObj.type == 'file') {
                        alert('Seleccione un archivo para cargar.');
                        fieldObj.focus();
                        return false;
                    }
                    alert(fldLangVal[i] + ' no puede estar vacío.');
                    fieldObj.focus();
                    event.preventDefault();
                    return false;
                } else if (fieldObj.nodeName == 'SELECT') {
                    if (fieldObj.options[fieldObj.selectedIndex].value == '-None-') {
                        alert(fldLangVal[i] + ' no puede ser none, favor de seleccionar una opción.');
                        fieldObj.focus();
                        event.preventDefault();
                        return false;
                    }
                } else if (fieldObj.type == 'checkbox') {
                    if (fieldObj.checked == false) {
                        alert('Por favor acepte  ' + fldLangVal[i]);
                        fieldObj.focus();
                        return false;
                    }
                }
                try {
                    if (fieldObj.name == 'Last Name') {
                        // name = fieldObj.value;
                    }
                } catch (e) { }
            }
        }
        if(!validateEmail1552583000122814074()){return false;}


        // document.querySelector('crmWebToEntityForm').setAttribute('disabled', true);
    }
    function tooltipShow1552583000122814074(el){
        var tooltip = el.nextElementSibling;
        var tooltipDisplay = tooltip.style.display;
        if(tooltipDisplay == 'none'){
            var allTooltip = document.getElementsByClassName('zcwf_tooltip_over');
            for(let i=0; i<allTooltip.length; i++){
                allTooltip[i].style.display='none';
            }
            tooltip.style.display = 'block';
        }else{
            tooltip.style.display='none';
        }
    }

    // const submitFunction = () => {
    //     // javascript:document.charset="UTF-8"; 


    //     return checkMandatory1552583000122814074()
    // }

    return (
        // <Layout>
        <React.Fragment>

            {/* <div id="crmWebToEntityForm" className={`${styles.zcwf_lblLeft} ${styles.crmWebToEntityForm}`} style={{ backgroundColor: "white", color: "black", maxWidth: "600px" }}> */}
            <div id="crmWebToEntityForm" className={`${styles.zcwf_lblLeft} ${styles.crmWebToEntityForm}`} style={{ backgroundColor: "white", color: "black", maxWidth: "600px", margin: "auto" }}>
                <Helmet>
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                    <meta httpEquiv="content-type" content="text/html;charset=UTF-8" />
                {/* </Helmet>
                <Helmet> */}
                        {/* <script src="./assets/vendor/preline/dist/form-contactanos.js" /> */}
                        <script id="wf_anal" src='https://crm.zohopublic.com/crm/WebFormAnalyticsServeServlet?rid=f2f5311914b78a71526baebcc1e777c67d40e6c6ab274bd4bf08353a6365a6cagidc11eaf6f80f1dad81f22c2268b397e1912b473a9775fc1518fc3092147ffa642gid885e3c1045bd9bdcc91bdf30f82b5696gid14f4ec16431e0686150daa43f3210513&tw=61690b96c1d0471b638f31426f38e68aa67fb7ed6da86f32dc10ad817fe55a0a'></script>
                    </Helmet>

                     {/* <div id="crmWebToEntityForm" className={`${styles.zcwf_lblLeft} ${styles.crmWebToEntityForm}`} style={{ backgroundColor: "white", color: "black", maxWidth: "600px" }}> */}
                    <form action="https://crm.zoho.com/crm/WebToLeadForm" name="WebToLeads1552583000122814074" method="POST" onSubmit={checkMandatory1552583000122814074} acceptCharset="UTF-8">
                    <input type="text" style={{ display: "none" }} name="xnQsjsdp" value={"c11eaf6f80f1dad81f22c2268b397e1912b473a9775fc1518fc3092147ffa642"} />
                    <input type="hidden" name="zc_gad" id='zc_gad' value="" />
                    <input type="text" style={{ display: "none" }} name="xmIwtLD" value={"f2f5311914b78a71526baebcc1e777c67d40e6c6ab274bd4bf08353a6365a6ca"} />
                    <input type="text" style={{ display: "none" }} name="actionType" value={"TGVhZHM="} />
                    <input type="text" style={{ display: "none" }} name="returnURL" value={"http://www.marcoc197.sg-host.com/"} />
                    {/* <input type='text' style={{ display: "none" }} name='returnURL' value='#' /> */}
                    {/* <stylesContactanos> </stylesContactanos> */}
                    {/* <Helmet>
      <style src="../styles/contactanos-form.scss"/>
      </Helmet> */}

                    {/* <div class='zcwf_title' style={{ maxWidth: "100%", color: "black" }}>Formulario Verticales</div> */}



                    <div className={styles.zcwf_row}>
                        <div className={`${styles.zcwf_col_lab}  ${stylesContactanos.labelTextForm}`}>
                            <label for="First_Name">Nombre<span style={{ color: "red" }}>*</span></label>
                        </div>
                        <div className={styles.zcwf_col_fld}>
                            <input type="text" className= {`${styles.inputsSizeFormContact} ${stylesContactanos.inputTextForm}`} id="First_Name" name="First Name" maxLength={40} />
                            <div className={styles.zcwf_col_help}>
                            </div>
                        </div>
                    </div>
                    <div className={styles.zcwf_row}>
                        <div className={`${styles.zcwf_col_lab} ${stylesContactanos.labelTextForm}`}>
                            <label for="Last_Name">Apellido<span style={{ color: "red" }}>*</span></label>
                        </div>
                        <div className={styles.zcwf_col_fld}>
                            <input className= {`${styles.inputsSizeFormContact} ${stylesContactanos.inputTextForm}`} type="text" id="Last_Name" name="Last Name" maxLength={80} />
                            <div className={styles.zcwf_col_help}>
                            </div>
                        </div>
                    </div>
                    <div className={styles.zcwf_row}>
                        <div className={`${styles.zcwf_col_lab}  ${stylesContactanos.labelTextForm}`}>
                            <label for="LEADCF26">Celular<span style={{ color: "red" }}>*</span></label>
                        </div>
                        <div className={styles.zcwf_col_fld}>
                            <input className= { `${styles.inputsSizeFormContact} ${stylesContactanos.inputTextForm}`} type="text" id="LEADCF26" name="LEADCF26" maxLength={255} />
                            <div className={styles.zcwf_col_help}>
                            </div>
                        </div>
                    </div>
                    <div className={styles.zcwf_row}>
                        <div className={`${styles.zcwf_col_lab}  ${stylesContactanos.labelTextForm}`}>
                            <label for="Email">Email</label>
                        </div>
                        <div className={styles.zcwf_col_fld}>
                            <input className= {`${styles.inputsSizeFormContact} ${stylesContactanos.inputTextForm}`} type="text" ftype="email" id="Email" name="Email" maxLength={100} />
                            <div className={styles.zcwf_col_help}>
                            </div>
                        </div>
                    </div>
                    <div className={styles.zcwf_row}>
                        <div className={`${styles.zcwf_col_lab} ${stylesContactanos.labelTextForm}`} >
                            <label id="LEADCF38">¿Cómo se entero de nosotros?<span style={{ color: "red" }}>*</span>
                            </label>
                        </div>
                        <div className={styles.zcwf_col_fld}>
                            <select className={`${styles.zcwf_col_fld_slt} ${styles.inputsSizeFormContact} ${stylesContactanos.inputTextForm}`} id="LEADCF38" name="LEADCF38"  >
                                <option value={"-None-"}>-None-</option>
                                <option value={"Facebook&#x20;Business"}>Facebook Business</option>
                                <option value={"Google&#x20;Business"}>Google Business</option>
                                <option value={"Instagram"}>Instagram</option>
                                <option value={"Tik&#x20;Tok"}>Tik Tok</option>
                                <option value={"LinkedIn"}>LinkedIn</option>
                                <option value={"Twitter"}>Twitter</option>
                                <option value={"WhatsApp&#x20;Business"}>WhatsApp Business</option>
                                <option value={"YouTube"}>YouTube</option>
                                <option value={"Televisi&oacute;n"}>Televisi&oacute;n</option>
                                <option value={"Revista"}>Revista</option>
                                <option value={"Peri&oacute;dico"}>Peri&oacute;dico</option>
                                <option value={"Cartelera"}>Cartelera</option>
                                <option value={"Recomendaci&oacute;n"}>Recomendaci&oacute;n</option>
                                <option value={"OCCMundial"}>OCCMundial</option>
                                <option value={"indeed"}>indeed</option>
                            </select>
                            <div className={styles.zcwf_col_help}>
                            </div>
                        </div>
                    </div>
                    <div className={styles.zcwf_row}>
                        <div className={`${styles.zcwf_col_lab} ${stylesContactanos.labelTextForm}`}>
                            <label for="LEADCF35">Comentarios</label>
                        </div>
                        <div className={`${styles.zcwf_col_fld} ${stylesContactanos.inputTextForm}`}>
                            <textarea id="LEADCF35" name="LEADCF35" style={{height: "100px" }} ></textarea>
                            <div className={styles.zcwf_col_help}>
                            </div>
                        </div>
                    </div>
                    <div className={styles.zcwf_row}>
                        <div className={styles.zcwf_col_lab}>

                        </div>
                        <div>
                        <input type='submit' id='formsubmit' className={`${styles.formsubmit} ${styles.zcwf_button} ${stylesContactanos.labelTextForm}`} value={"Enviar"} title={"Submit"} />
                            {/* <input type={"reset"} className={styles.zcwf_button} name={"reset"} value={"Reset"} title={"Reset"}/> */}
                        </div>
                    </div>
                    {/* <scriptContactanos></scriptContactanos>  */}
                    
                </form>

            </div>
            {/* </div> */}{/* </Layout> */}
        </React.Fragment>


    )
}

// export default formContactanos