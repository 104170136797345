import React from "react"
import * as styles from "../styles/index.module.css"
import * as stylesContactanos from "../styles/contactanos.module.css"

import { styled } from "@material-ui/core"
import Box from "@material-ui/core/Box"
import Paper from "@material-ui/core/Paper"
import Grid from "@material-ui/core/Grid"

// import imgPuntazul from "../images/puntazul.png"
import imgCarrera from "../images/carrera.jpg"
import imgDespensas from "../images/despensas_covid.jpg"
import imgReconocimientos from "../images/reconocimiento.jpg"
import { Helmet } from "react-helmet"
// import Link from 'gatsby-link'
import Layout from "../components/layout"
import FormContactanos from "../components/form-contactanos"

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}))

const contactenos = () => (
  <Layout>
          <Helmet>
                <title>Contactános</title>
            </Helmet>
    <section id="contactanos">
      <Box sx={{ flexGrow: 1 }}>
        {/* {console.log(items)} */}
        {/* {items.map((item, index) => ( */}

        <Grid key={1} container className={styles.gridQS}>
          {/* {item.map(item => (
           */}
          <Grid key={"item-title"} item lg={10} md={10} sm={10} xs={11}>
            <h1 className={`${styles.titleQS} ${styles.titleMarginQS}`} style={{marginTop: "25px"}}>
              {/* Aries Fortalece tu Patrimonio cumple 20 años Fortaleciendo el
              Patrimonio de las Familias. ¡¡¡Enhorabuena!!! */}
             Contactános
            </h1>
          </Grid>
          <Grid key={"item-1"} item lg={10} md={10} sm={10} xs={11}>
              <Item key={"item1"} className={styles.gridItemQS}>
              <div
                className={stylesContactanos.itemDivImgC}
                style={{
                  borderRadius: "5px",
                  maxHeight: "800px",
                  height: "80vw",
                  minHeight: "400px",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundImage: `url("/_DSC3460_Small.jpg")`,
                }}
              >
                {/* <img  alt="Img" className={styles.imgQuienesSomos} key={"img"} src={imgPuntazul}/> */}
              </div>
            </Item>
          </Grid>
          <Grid key={"item-1"} item lg={10} md={10} sm={10} xs={11}>
            <Item key={"item1"} className={stylesContactanos.gridItemC}>
              <div className={stylesContactanos.itemDivContainerC} style={{marginTop: "60px"}}>
                <p className={styles.subtitleLeftQS}>
                <strong> Email: </strong> <a href="mailto:info@mipatrimonio.com" className={stylesContactanos.contactInformation}>info@mipatrimonio.com </a>
                </p>
                <p className={styles.subtitleLeftQS}>
                <strong> Teléfono: </strong> <a href="tel:5521673605" className={stylesContactanos.contactInformation}>(552) 167 36 05 </a>
                </p>
                <p className={styles.subtitleLeftQS}>
                    <strong> Dirección: </strong> <span>Periférico Blvd. Manuel Ávila Camacho 156, Reforma Social, Miguel Hidalgo, 11650 Ciudad de México, CDMX. </span> 
                </p>

                <iframe className={stylesContactanos.iframeMaps}
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3762.507347190693!2d-99.21302078255614!3d19.433680800000005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d2021f448bae0f%3A0x806ad9a9f55cd4bd!2sPerif.%20Blvd.%20Manuel%20%C3%81vila%20Camacho%20156%2C%20Reforma%20Soc%2C%20Miguel%20Hidalgo%2C%2011650%20Ciudad%20de%20M%C3%A9xico%2C%20CDMX!5e0!3m2!1ses-419!2smx!4v1658263054314!5m2!1ses-419!2smx"
                allowFullScreen="" loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"></iframe>
              </div>
              <div className={stylesContactanos.itemDivContainerC}>
              <p  className={stylesContactanos.titleForm}> <strong> Formulario de contacto </strong></p>
              <FormContactanos> </FormContactanos>
            {/* <iframe src="https://survey.zohopublic.com/zs/SCBUyc" frameborder='0' 
                style={{
                    height:"920px",
                    width:"100%"
                }}
                marginwidth='0' marginheight='0' scrolling='auto' allow='geolocation'></iframe> */}
              </div>
            </Item>
          </Grid>

          {/* ))} */}
        </Grid>
        {/* ))} */}
      </Box>
    </section>
  </Layout>
)

export default contactenos
